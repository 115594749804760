.text-bg-primary {
    --card-box-bg-color: #{$purple-700};
    --card-box-bg-color-hover: #{$purple-300};
    --card-box-bg-color-focus: #{$purple-700};
    --card-box-title-color: #{$black};
    --carousel-bg-color: #{$light};
    --carousel-content-color: #{$black};
    --hero-bg-image-bg-color: #{$purple-300};
    --hero-content-color: #{$black};
    --hero-content-headings-color: #{$cyan-700};
    --intro-cta-bg-color: #{$purple-300};
    --intro-cta-title-color: #{$cyan-700};
    --quote-bg-color: #{$purple-300};
    --quote-quotation-color: #{$cyan-700};
    --quote-person-color: #{$cyan-700};
    --tower-accent-color: #{$yellow};
    --tower-bg-color: #{$purple-700};
    --tower-border-color: #{$purple-300};
    --tower-content-color: #{$white};
    --tower-title-color: #{$purple-300};
    --#{$prefix}heading-color: #{$black};
}

.text-bg-secondary {
    --card-box-bg-color: #{$blue-600};
    --card-box-bg-color-hover: #{$purple-300};
    --card-box-bg-color-focus: #{$purple-700};
    --card-box-title-color: #{$white};
    --carousel-bg-color: #{$light};
    --carousel-content-color: #{$black};
    --hero-bg-image-bg-color: #{$blue-600};
    --hero-content-color: #{$white};
    --hero-content-headings-color: #{$purple};
    --intro-cta-bg-color: #{$blue-300};
    --intro-cta-title-color: #{$light};
    --quote-bg-color: #{$blue-600};
    --quote-quotation-color: #{$purple};
    --quote-person-color: #{$light};
    --tower-accent-color: #{$yellow};
    --tower-bg-color: #{$blue-700};
    --tower-border-color: #{$blue-600};
    --tower-content-color: #{$white};
    --tower-title-color: #{$purple};
    --#{$prefix}heading-color: #{$white};
    color: $white !important;
}

.text-bg-tertiary {
    --card-box-bg-color: #{$cyan-600};
    --card-box-bg-color-hover: #{$purple-300};
    --card-box-bg-color-focus: #{$purple-700};
    --card-box-title-color: #{$white};
    --carousel-bg-color: #{$light};
    --carousel-content-color: #{$black};
    --hero-bg-image-bg-color: #{$cyan-600};
    --hero-content-color: #{$white};
    --hero-content-headings-color: #{$purple-300};
    --intro-cta-bg-color: #{$cyan-400};
    --intro-cta-title-color: #{$light};
    --quote-bg-color: #{$cyan-600};
    --quote-quotation-color: #{$purple-300};
    --quote-person-color: #{$light};
    --tower-accent-color: #{$yellow};
    --tower-bg-color: #{$cyan-700};
    --tower-border-color: #{$cyan-600};
    --tower-content-color: #{$white};
    --tower-title-color: #{$purple};
    --#{$prefix}heading-color: #{$white};
    --card-office-title-color: #{$purple};
}

.text-bg-quaternary {
    --card-box-bg-color: #{$orange-600};
    --card-box-bg-color-hover: #{$yellow-200};
    --card-box-bg-color-focus: #{$yellow-600};
    --card-box-title-color: #{$white};
    --carousel-bg-color: #{$light};
    --carousel-content-color: #{$black};
    --hero-bg-image-bg-color: #{$orange-600};
    --hero-content-color: #{$white};
    --hero-content-headings-color: #{$yellow};
    --intro-cta-bg-color: #{$orange-300};
    --intro-cta-title-color: #{$light};
    --quote-bg-color: #{$orange-600};
    --quote-quotation-color: #{$yellow};
    --quote-person-color: #{$light};
    --tower-accent-color: #{$yellow};
    --tower-bg-color: #{$orange-700};
    --tower-border-color: #{$orange-600};
    --tower-content-color: #{$white};
    --tower-title-color: #{$purple};
    --#{$prefix}heading-color: #{$white};
    color: $white !important;
}

.text-bg-light {
    --card-box-bg-color: #{$white};
    --card-box-bg-color-hover: #{$purple-300};
    --card-box-bg-color-focus: #{$purple-700};
    --card-box-title-color: #{$black};
    --carousel-bg-color: #{$white};
    --carousel-content-color: #{$black};
    --hero-bg-image-bg-color: #{$white};
    --hero-content-color: #{$black};
    --hero-content-headings-color: #{$black};
    --intro-cta-bg-color: #{$white};
    --intro-cta-title-color: #{$cyan-700};
    --quote-bg-color: #{$white};
    --quote-quotation-color: #{$blue};
    --quote-person-color: #{$light};
    --tower-accent-color: #{$yellow-600};
    --tower-bg-color: #{$white};
    --tower-border-color: #{$blue-400};
    --tower-content-color: #{$black};
    --tower-title-color: #{$blue-400};
    --#{$prefix}heading-color: #{$black};
}

.text-bg-dark {
    background-color: $black;
    --card-box-bg-color: #{$blue-600};
    --card-box-bg-color-hover: #{$purple-300};
    --card-box-bg-color-focus: #{$purple-700};
    --card-box-title-color: #{$white};
    --carousel-bg-color: #{$light};
    --carousel-content-color: #{$black};
    --hero-bg-image-bg-color: #{$blue-600};
    --hero-content-color: #{$white};
    --hero-content-headings-color: #{$purple};
    --intro-cta-bg-color: #{$blue-600};
    --intro-cta-title-color: #{$light};
    --quote-bg-color: #{$cyan-600};
    --quote-quotation-color: #{$purple};
    --quote-person-color: #{$light};
    --tower-accent-color: #{$yellow-600};
    --tower-bg-color: #{$light};
    --tower-border-color: #{$yellow-600};
    --tower-content-color: #{$black};
    --tower-title-color: #{$purple-700};
    --#{$prefix}heading-color: #{$white};
    --card-office-title-color: #{$light};
    color: $white !important;
}
