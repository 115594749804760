section {
    &:not([rel="section-fluid"]) {
        padding: 3rem 0;
    }

    &:first-child {
        &:not([rel="section-fluid"]) {
            padding-top: 7.125rem;
        }
    }

    &:last-child {
        margin-bottom: -2rem;
        padding-bottom: 5rem;
    }

    @include media-breakpoint-up(md) {
        &:first-child {
            &:not([rel="section-fluid"]) {
                padding-top: 9.125rem;
            }
        }
    }
}
