.hero {
    @import "hero/mask";

    &.ratio {
        --#{$prefix}aspect-ratio: calc(100vh - 196px);
        min-height: 520px;
        overflow: hidden;
        padding: 98px 0;
    }

    > .container-lg {
        inset: 0;
    }

    .hero-bg-image {
        background-color: var(--hero-bg-image-bg-color, $white);
        display: block;
        height: 100%;
        width: 100%;
        overflow: hidden;

        .img-fluid {
            height: 100%;
            object-fit: cover;
            object-position: center center;
            width: 100%;
        }
    }

    .hero-content {
        align-items: flex-start;
        color: var(--hero-content-color, $black);
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        max-width: 800px;

        @include headings() {
            color: var(--hero-content-headings-color, $black);
        }
    }

    @include media-breakpoint-down(md) {
        &.ratio {
            --#{$prefix}aspect-ratio: calc(100vh - 132px);
            padding: 66px 0;

            // experimental css for vertical sm version only
            &:has(.vertical-sm) {
                &::before {
                    padding: 0;
                }
                display: flex;
                flex-direction: column-reverse;
                gap: 3rem;
                > * {
                    position: relative;
                }
                .hero-bg-image {
                    mask-position: center;
                }
            }
        }
    }
}
