.mask {
    mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 147 169.2" xml:space="preserve"><path d="m147,81.16H0v49.77c0,21.14,17.5,38.28,39.1,38.28h107.9v-88.05Zm0-42.87c0-21.14-17.5-38.28-39.1-38.28H0v42.88c0,21.14,7.88,38.28,29.48,38.28h117.52v-42.87Z"/></svg>');
    mask-size: cover;
    mask-repeat: no-repeat;

    img {
        aspect-ratio: 147/169.2;
        object-fit: cover;
        object-position: center center;
    }

    &.square {
        mask-image: none;

        img {
            border-radius: 0 15px 15px;
        }
    }
}
