form {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .ktc-default-section {
        background-color: $white;
        border-radius: 0 5rem 5rem 5rem;
        flex: 1 1 100%;
        min-height: 14rem;
        padding: 3rem 4rem;

        &:nth-of-type(even) {
            border-radius: 5rem 5rem 5rem 0;
        }
    }

    input {
        &[type="submit"] {
            @extend .btn;
            @extend .btn-light !optional;
            bottom: 3rem;
            position: absolute;
            right: 4rem;
        }
    }

    .form-field {
        margin-bottom: 2rem;
    }

    .form-control {
        --#{$prefix}body-bg: transparent;
        --#{$prefix}body-color: #{$black};
        --#{$prefix}border-color: #{$black};
        --#{$prefix}border-width: 0;
        --#{$prefix}border-radius: 0;
        border-bottom-width: 2px;

        &[type="checkbox"],
        &[type="radio"] {
            width: auto;
            padding: 0;
            appearance: none; // Remove box-shadow, border-radius effects for checkboxes
            -webkit-appearance: checkbox;
            -moz-appearance: checkbox;
        }

        &:focus {
            border-color: $purple-700;
            box-shadow: none;
        }
    }

    .control-label {
        @extend .form-label;
        display: none;
    }

    .field-validation-error {
        color: #ff6a00;
        font-size: 1rem;
    }

    .ktc-checkbox {
        display: flex;
        gap: 1rem;

        input[type="checkbox"] {
            cursor: pointer;
            width: auto;
            padding: 0;
            appearance: checkbox;
        }

        label {
            cursor: pointer;
            font-size: 1rem;

            p:last-child {
                margin-bottom: 0;
            }
        }

        input[type="hidden"] {
            display: none;
        }
    }

    .ktc-checkbox-list {
        display: block;
        padding-left: 0;
        margin-bottom: 1rem;

        .ktc-checkbox {
            align-items: center;
            margin-bottom: 10px;
        }
    }

    @include media-breakpoint-up(lg) {
        .ktc-default-section {
            flex-basis: 0%;
        }
    }
}
