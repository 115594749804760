[data-module="scroll-to-top"] {
    align-items: center;
    border: none;
    border-radius: 50%;
    bottom: 0;
    display: flex;
    height: 50px;
    flex-direction: column;
    font-size: 2.5rem;
    justify-content: center;
    opacity: 0;
    padding: 0;
    position: fixed;
    right: 20px;
    transition: all 0.2s ease !important;
    visibility: hidden;
    width: 50px;
    z-index: $zindex-sticky;

    @include media-breakpoint-up(lg) {
        &[data-enabled="true"] {
            &.visible {
                bottom: 20px;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
