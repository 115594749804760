.intro-cta {
    .title {
        color: var(--intro-cta-title-color, $cyan-700);
        padding: 0 0 0 35px;
    }

    .mask {
        background-color: var(--intro-cta-bg-color, $white);
        padding: 50px;
        position: relative;

        &.square {
            border-radius: 62px 62px 0 62px;
        }
    }

    @include media-breakpoint-down(sm) {
        .title {
            margin: 0;
            padding: 0;
        }

        .mask {
            background-color: transparent;
            padding: 40px calc(0.5 * var(--#{$prefix}gutter-x));

            &.square {
                border-radius: 0;

                &:before {
                    content: "";
                    inset: 0 0 0 0;
                    overflow: hidden;
                    position: absolute;
                }

                &:after {
                    background-color: $white;
                    border-radius: 62px 0 0 62px;
                    content: "";
                    inset: 0 0 0 45%;
                    position: absolute;
                    z-index: -1;
                    width: calc(55% + 0.5 * var(--#{$prefix}gutter-x));
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .title {
            max-width: 430px;
            padding: 0;
            position: relative;
            top: 160px;
            z-index: 1;
        }

        .mask {
            margin: 0 0 0 auto;
            max-width: 775px;
            padding: 220px 110px 110px;
            top: -4rem;

            &.square {
                border-radius: 124px 124px 0 124px;
            }
        }
    }
}
