.carousel {
    display: grid;
    grid-auto-columns: 8fr;
    grid-auto-rows: 7fr 1fr;

    .carousel-indicators {
        grid-column: span 3;
        grid-row: 2;
        position: relative;
    }

    .carousel-inner {
        grid-row: 1;
        grid-column: span 3;
    }

    .carousel-item {
        align-items: center;
        height: 100%;
        justify-content: space-between;

        &.carousel-item-next,
        &.carousel-item-prev {
            &.carousel-item-start,
            &.carousel-item-end {
                display: flex;
            }
        }

        &.active {
            display: flex;
        }
    }

    .item-body {
        background-color: var(--carousel-bg-color, $primary-3);
        border-radius: 0 60px 60px 60px;
        color: var(--carousel-content-color, $black);
        display: flex;
        flex: 0 0 75%;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 4% 6.4%;
    }

    .item-img {
        aspect-ratio: 243/272;
        border-radius: 60px 60px 60px 0;
        flex: 0 0 25%;
        overflow: hidden;

        .img-fluid {
            height: 100%;
            object-fit: cover;
            object-position: center center;
            width: 100%;
        }
    }

    .quote {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.3333;
    }

    .carousel-control-prev,
    .carousel-control-next {
        display: none;
    }

    @include media-breakpoint-down(lg) {
        .person {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            flex: 1 0 7rem;
            justify-content: flex-end;
            margin: 1.5rem auto 0 7rem;
        }

        .item-body {
            border-radius: 0 2rem 2rem 2rem;
            flex-basis: 100%;
            padding: 1.5rem;
        }

        .item-img {
            border-radius: 1.5rem 1.5rem 1.5rem 0;
            bottom: 1.5rem;
            left: 1.5rem;
            max-height: 6.5rem;
            max-width: 6.5rem;
            position: absolute;
        }
    }

    @include media-breakpoint-up(lg) {
        grid-auto-columns: 60px 8fr 60px;

        .carousel-inner {
            grid-column: 2;
        }

        .carousel-control-prev,
        .carousel-control-next {
            background-color: var(--carousel-bg-color, $primary-3);
            border-radius: 60px 60px 60px 0;
            display: flex;
            grid-row: 1;
            height: 100%;
            opacity: 1;
            position: relative;

            &:hover,
            &:active,
            &:focus {
                > span {
                    color: $black;
                }
            }
        }

        .carousel-control-prev {
            grid-column: 1;
        }

        .carousel-control-next {
            grid-column: 3;
        }

        .carousel-indicators {
            display: none;
        }
    }
}
