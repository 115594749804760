@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?r270af') format('woff2'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?r270af') format('woff'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?r270af') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

%icon,
[class^="icon-"],
[class*=" icon-"] {
    /* don't use !important to prevent issues with Kentico */
    font-family: '#{$icomoon-font-family}';
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-map-marker {
    &:before {
        content: $icon-map-marker;
    }
}

.icon-globe {
    &:before {
        content: $icon-globe;
    }
}

.icon-envelope {
    &:before {
        content: $icon-envelope;
    }
}

.icon-phone {
    &:before {
        content: $icon-phone;
    }
}

.icon-bars {
    &:before {
        content: $icon-bars;
    }
}

.icon-chevron-up {
    &:before {
        content: $icon-chevron-up;
    }
}

.icon-chevron-right {
    &:before {
        content: $icon-chevron-right;
    }
}

.icon-chevron-down {
    &:before {
        content: $icon-chevron-down;
    }
}

.icon-chevron-left {
    &:before {
        content: $icon-chevron-left;
    }
}

.icon-close {
    &:before {
        content: $icon-close;
    }
}

.icon-facebook-square {
    &:before {
        content: $icon-facebook-square;
    }
}

.icon-instagram-square {
    &:before {
        content: $icon-instagram-square;
    }
}

.icon-linkedin-square {
    &:before {
        content: $icon-linkedin-square;
    }
}

.icon-pinterest-square {
    &:before {
        content: $icon-pinterest-square;
    }
}

.icon-twitter {
    &:before {
        content: $icon-twitter;
    }
}

.icon-x {
    &:before {
        content: $icon-x;
    }
}

.icon-youtube {
    &:before {
        content: $icon-youtube;
    }
}

.icon-rocket {
    &:before {
        content: $icon-rocket;
    }
}

.icon-jet {
    &:before {
        content: $icon-jet;
    }
}

.icon-spaceship {
    &:before {
        content: $icon-spaceship;
    }
}

.icon-cog {
    &:before {
        content: $icon-cog;
    }
}
