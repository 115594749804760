@font-face {
    font-family: $font-family-name;
    src: url('#{$font-family-path}/apfel-grotezk/#{$font-family-regular}.woff2') format('woff2'), /* Super modern browsers */
    url('#{$font-family-path}/apfel-grotezk/#{$font-family-regular}.woff') format('woff'), /* Modern browsers */
    url('#{$font-family-path}/apfel-grotezk/#{$font-family-regular}.otf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: $font-family-name;
    src: url('#{$font-family-path}/apfel-grotezk/#{$font-family-medium}.woff2') format('woff2'), /* Super modern browsers */
    url('#{$font-family-path}/apfel-grotezk/#{$font-family-medium}.woff') format('woff'), /* Modern browsers */
    url('#{$font-family-path}/apfel-grotezk/#{$font-family-medium}.otf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}
