.contact-cta {
    display: flex;
    height: 100%;

    .description {
        align-items: flex-start;
        display: flex;
        flex: 1 1 80%;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding-right: 2.5rem;
    }

    .card {
        flex: 0 0 20%;
    }
}
