@mixin dropdown-toggle {
    @extend .btn;
    @extend .btn-dark;
}

@mixin nav-button {
    @extend .btn;
    @extend .btn-primary;
}

.navbar {
    &.navbar-main {
        --#{$prefix}navbar-toggler-border-color: transparent;
        --#{$prefix}navbar-toggler-focus-width: 0;

        .nav-btn {
            @include nav-button;
        }

        .dropdown-toggle {
            @include dropdown-toggle;
        }

        @include media-breakpoint-down(md) {
            .offcanvas {
                background-color: $purple;
                color: $dark;

                .offcanvas-header {
                    background-color: $purple;
                    position: sticky;
                }
            }

            .nav {
                align-items: center;
                flex-direction: column;
                justify-content: center;
                gap: 3.75rem;
                height: calc(100% - 60px);
                flex-wrap: nowrap;

                .nav-link,
                .btn {
                    background-color: transparent;
                    border: none;
                    color: inherit;
                    font-size: 36px;
                    font-weight: 500;
                    line-height: 1.11;
                    padding: 0;

                    &:hover,
                    &:active,
                    &:focus {
                        color: $white;
                    }

                    &[aria-current="page"] {
                        color: $white;
                    }
                }
            }

            .language-switcher {
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
            }

            .dropdown-toggle {
                &.show {
                    background-color: $purple-700;
                    border-radius: 0 30px 30px;
                    justify-content: center;
                    padding: 1rem 0;
                    width: 100%;
                }
            }

            .dropdown-menu {
                background-color: $purple-700;
                border-radius: 30px 30px 0;
                padding: 1rem 0;
                position: relative;
                top: 0;
                width: 100%;
            }

            .dropdown-item {
                color: $white;
                font-size: 24px;
                justify-content: center;
                padding-bottom: 1rem;
                padding-top: 1rem;
            }
        }

        @include media-breakpoint-up(md) {
            .nav {
                .nav-item {
                    align-items: center;
                    display: flex;
                    padding: 0 var(--#{$prefix}nav-link-padding-x, 1rem);
                }

                .nav-link {
                    --#{$prefix}nav-link-color: #{$black};
                    --#{$prefix}nav-link-hover-color: #{$black};
                    padding: var(--#{$prefix}nav-link-padding-y, 0.5rem) 0;
                    position: relative;

                    &:after {
                        background-color: $purple;
                        bottom: 0;
                        content: '';
                        left: 0;
                        height: 1px;
                        opacity: 1;
                        position: absolute;
                        transform: scale(0);
                        transition: transform ease-in 0.3s;
                        width: 100%;
                    }

                    &[aria-current="page"] {
                        &:after {
                            height: 2px;
                            opacity: 1;
                            transform: scale(1);
                        }
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        &:after {
                            background-color: $purple-700;
                            height: 2px;
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
}

.scrolled {
    .navbar {
        &.navbar-main {
            background-color: $light;
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
        }
    }
}
