.card-layout {
    &.continent-overview {
        .col {
            &:nth-child(6n+1),
            &:nth-child(6n+5) {
                .mask-1 {
                    border-top-right-radius: 0;
                }

                .mask-2 {
                    border-bottom-left-radius: 0;
                }
            }

            &:nth-child(6n+2),
            &:nth-child(6n+6) {
                .mask-1 {
                    border-bottom-right-radius: 0;
                }

                .mask-2 {
                    border-top-left-radius: 0;
                }
            }

            &:nth-child(6n+3) {
                .mask-1 {
                    border-bottom-left-radius: 0;
                }

                .mask-2 {
                    border-top-right-radius: 0;
                }
            }

            &:nth-child(6n+4) {
                .mask-1 {
                    border-top-left-radius: 0;
                }

                .mask-2 {
                    border-bottom-right-radius: 0;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            [data-count="5"] {
                display: grid;
                grid-auto-columns: 3fr 3fr 3fr;
                grid-auto-rows: 3fr 3fr 3fr;

                .col {
                    margin: 0;
                    padding: 0;
                    width: auto;

                    &:nth-child(5n + 1) {
                        grid-column: 1;
                        grid-row: 1;
                    }

                    &:nth-child(5n + 2) {
                        grid-column: 3;
                        grid-row: 1;
                    }

                    &:nth-child(5n + 3) {
                        grid-column: 2;
                        grid-row: 2;
                    }

                    &:nth-child(5n + 4) {
                        grid-column: 1;
                        grid-row: 3;
                    }

                    &:nth-child(5n + 5) {
                        grid-column: 3;
                        grid-row: 3;
                    }
                }
            }
        }
    }

    &.office-overview {
        @include media-breakpoint-up(lg) {
            .col {
                border-right: 1px solid $white;

                &:last-child {
                    border-right: none;
                }
            }
        }
    }
}
