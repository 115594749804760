.nav {
    &.nav-tabs {
        &.tabs-icon {
            --#{$prefix}emphasis-color: #{$yellow};
            --#{$prefix}body-bg: #{$blue-600};
            --#{$prefix}nav-tabs-border-radius: 30px;
            --#{$prefix}nav-tabs-border-width: 0;
            --#{$prefix}nav-link-color: #{$black};
            --#{$prefix}nav-link-padding-y: 0;
            --#{$prefix}nav-link-padding-x: 0;

            .nav-link {
                border-top-left-radius: 0;
                border-bottom-left-radius: var(--#{$prefix}nav-tabs-border-radius);
                border-bottom-right-radius: var(--#{$prefix}nav-tabs-border-radius);
                height: 56px;
                width: 56px;

                &:not(.active) {
                    background-color: $white;
                }

                > span {
                    display: none;
                }
            }

            .fas {
                font-size: 1rem;
            }

            + .tab-content {
                .tab-pane {
                    grid-auto-columns: 1fr;
                    grid-auto-rows: 3fr 7fr;
                    padding: 3rem 0;

                    &.active {
                        display: grid;
                    }
                }

                .content-title {
                    grid-column: 1;
                    grid-row: 1;
                }

                .content-text {
                    grid-column: 1;
                    grid-row: 2;
                    padding-right: 4rem;
                    position: relative;

                    .fas {
                        font-size: 2rem;
                        padding: 4px;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            &.tabs-icon {
                position: relative;

                &:after {
                    border-bottom: 1px solid $blue-600;
                    content: '';
                    position: relative;
                    min-width: 50%;
                    left: 35px;
                    height: 26px;
                }

                .nav-link {
                    height: 48px;
                    width: 48px;
                }

                + .tab-content {
                    .tab-pane {
                        grid-auto-columns: 3fr 7fr;
                        grid-auto-rows: 1fr;
                    }

                    .content-text {
                        grid-column: 2;
                        grid-row: 1;
                    }
                }
            }
        }
    }
}
