.language-switcher {
    position: relative;

    .dropdown-toggle {
        align-items: center;
        display: flex;

        .flag {
            height: auto;
            width: 28px;
        }
    }

    .dropdown-menu {
        .dropdown-item {
            align-items: center;
            display: flex;
            gap: 0.5rem;

            .flag {
                height: auto;
                width: 24px;
            }
        }
    }
}
