footer {
    border-radius: 0 30px 0 0;

    .copyright {
        font-size: 1rem;
    }

    .brand-logo {
        width: 168px;
    }

    .slogan {
        font-size: 1.5rem;
    }

    .nav {
        .nav-link {
            color: inherit;

            &:hover,
            &:active,
            &:focus {
                text-decoration-color: $purple-300;
                text-decoration-style: solid;
                text-decoration-line: underline;
                text-decoration-thickness: 2px;
            }
        }

        &.nav-legal {
            .nav-link {
                font-size: 0.875rem;
            }
        }

        &.nav-social {
            .nav-link {
                font-size: 2rem;
                line-height: 1;

                &:hover,
                &:active,
                &:focus {
                    color: $purple;
                }
            }

            .xp-brand-facebook {
                &:before {
                    content: "#{$fa-var-facebook-square}";
                }
            }

            .xp-brand-linkedin {
                &:before {
                    content: "#{$fa-var-linkedin}";
                }
            }

            .xp-brand-twitter {
                &:before {
                    content: "#{$fa-var-square-x-twitter}";
                }
            }

            .xp-brand-youtube {
                &:before {
                    content: "#{$fa-var-square-youtube}";
                }
            }

            .xp-brand-pinterest {
                &:before {
                    content: "#{$fa-var-square-pinterest}";
                }
            }

            .xp-brand-instagram {
                &:before {
                    content: "#{$fa-var-square-instagram}";
                }
            }
        }
    }

    .row-start {
        .col-end {
            border-bottom: 1px dotted $blue-600;
            border-top: 1px solid $blue-600;
        }
    }

    .row-end {
        position: relative;

        .col-start {
            border-bottom: 1px solid $blue-600;
            border-top: none;
        }
    }

    @include media-breakpoint-down(md) {
        .copyright {
            left: 25px;
            position: absolute;
            top: 75%;
        }

        .nav {
            &.nav-legal {
                align-items: center;
                display: flex;
                justify-content: center;
                width: 100%;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .slogan {
            margin: 1rem 0 2rem;

            p {
                margin-bottom: 0;
            }
        }

        .row-start,
        .row-end {
            .col-start,
            .col-end {
                border-bottom: none;
                border-top: none;
            }
        }

        .row-end {
            .col-start {
                display: flex;
            }
        }
    }
}
