[data-module="insights"] {
    .insight-filters {
        position: relative;
        z-index: 2;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        gap: 25px;
        margin-bottom: 50px;

        li {
            border-bottom: 2px solid $purple-700;
            cursor: pointer;

            &.active {
                color: $purple-700;
            }
        }
    }
}
