.hero-bg-image {
    mask-repeat: no-repeat;
    width: 100%;

    &.horizontal {
        height: calc(100% - 66px);
        mask-image: url("#{$assets-path}/hero-mask-horizontal-sm.svg");
        mask-position: 100% 50%;
        mask-size: contain;
        transform: translateY(66px);
    }

    &.vertical {
        mask-image: url("#{$assets-path}/hero-mask-vertical.svg");
        mask-position: 0;
        mask-size: cover;
        transform: translateX(50%);
    }

    &.diagonal {
        mask-image: url("#{$assets-path}/hero-mask-diagonal.svg");
        mask-position: 0 0;
        mask-size: contain;
    }

    &.vertical-sm {
        mask-image: url("#{$assets-path}/hero-mask-vertical-sm.svg");
        mask-position: 100%;
        mask-size: contain;
        left: auto;
    }

    @include media-breakpoint-up(md) {
        &.horizontal {
            mask-image: url("#{$assets-path}/hero-mask-horizontal-lg.svg");
            mask-size: contain;
            mask-position: 100% 50%;
            height: calc(100% - 196px);
            transform: translateY(98px);
        }

        &.vertical {
            mask-size: contain;
            mask-position: 85% 0;
            transform: translateX(0);
        }

        &.vertical-sm {
            mask-size: contain;
            mask-position: 100%;
            height: calc(100% - 98px);
            transform: translateY(98px);
            right: 3rem;
            width: calc(((720px / 12) * 4) + ((100vw - 720px - 6rem) / 2));
        }
    }

    @include media-breakpoint-up(lg) {
        &.vertical-sm {
            width: calc(((960px / 12) * 4) + ((100vw - 960px - 6rem) / 2));
        }
    }

    @include media-breakpoint-up(xl) {
        &.vertical-sm {
            width: calc(((1140px / 12) * 4) + ((100vw - 1140px - 6rem) / 2));
        }
    }

    @include media-breakpoint-up(xxl) {
        &.vertical-sm {
            width: calc(((1320px / 12) * 4) + ((100vw - 1320px - 6rem) / 2));
        }
    }
}
