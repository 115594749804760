.card {
    &.card-continent {
        background-color: transparent;
        border: none;
        height: 100%;

        &[role="button"] {
            cursor: default;
        }

        .card-header {
            align-items: center;
            background-color: transparent;
            border: none;
            color: $white;
            display: flex;
            justify-content: center;
            height: 100%;
            position: absolute;
            transition: all ease-in 0.25s;
            width: 100%;
        }

        .card-body {
            display: none;
        }

        .card-title {
            align-items: center;
            color: var(--card-box-title-color, $black);
            display: inline-flex;
            gap: 0.5rem;
            font-size: 1.5rem;
            letter-spacing: 2px;
            text-align: center;
        }

        .box {
            background-color: var(--card-box-bg-color, $cyan-600);
            border-radius: 25px;
            width: 50.1%;
            transition: all ease-out 0.25s;
        }

        .mask-1 {
            left: 0;
            right: auto;
        }

        .mask-2 {
            left: auto;
            right: 0;
        }

        .fas {
            opacity: 0;
            transition: all ease-in 0.25s;
            width: 0;
        }

        &:hover,
        &:active,
        &:focus {
            .card-header {
                color: $dark;
            }

            .box {
                background-color: var(--card-box-bg-color-hover, $purple-300);
            }

            .mask-1 {
                width: 60%;
            }

            .mask-2 {
                width: 40%;
            }

            .fas {
                opacity: 1;
                width: 30px;
            }
        }

        &:active,
        &:focus {
            .box {
                background-color: var(--card-box-bg-color-focus, $purple-700);
            }
        }

        @include media-breakpoint-up(lg) {
            .box {
                border-radius: 50px;
            }
        }
    }

    &.card-office {
        background-color: transparent;
        border: none;
        color: $white;
        height: 100%;

        .card-header {
            background-color: transparent;
            border: none;
            color: $purple-500;
            padding-top: 0;
            padding-bottom: 0;
        }

        .card-body {
            padding-bottom: 0;
        }

        .card-title {
            font-size: 2rem;
            margin-bottom: 0;
            color: var(--card-office-title-color, $black);
        }

        .links {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .link-maps {
            color: $blue-300;

            &:hover,
            &:active,
            &:focus {
                color: $white;
                text-decoration-color: $blue-300;
            }
        }

        .link-mail {
            align-items: center;
            color: $yellow;
            display: flex;
            gap: 1rem;
            text-decoration: none;

            &:hover,
            &:active,
            &:focus {
                color: $yellow-200;

                span {
                    text-decoration: underline;
                }
            }
        }
    }

    &.card-contact {
        background-color: transparent;
        border: none;

        .card-text {
            font-size: 0.85rem;
            line-height: 1;
        }
    }

    &.card-insight {
        border: none;
        border-radius: 0;
        display: flex;
        flex-direction: column;
        background-color: transparent;
        
        .card-image {
            padding-top: 80%;
            position: relative;
            border-top-right-radius: 30px;
            border-bottom-left-radius: 30px;
            overflow: hidden;
            flex-grow: 0;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                margin: 0;
            }
        }
        .card-body {
            background-color: $white;
            border-top-left-radius: 30px;
            border-bottom-right-radius: 30px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }
        .card-tags {
            position: relative;
            z-index: 2;
            list-style: none;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            padding: 0;
            margin-bottom: 20px;
            
            li {
                &, a {
                    color: $blue-500;
                    text-decoration: none;
                    font-size: .875rem;
                    line-height: 1rem;
                    font-weight: 500;
                }
                &:not(:last-of-type) {
                    border-right: 1px solid $blue-500;
                    padding-right: 10px;
                }
            }
        }
        .card-title {
            margin-bottom: 25px;
        }
        .btn {
            margin-top: auto;
            align-self: flex-end;
        }
        
        @include media-breakpoint-up(md) {
            height: var(--oh, 100%);
            .card-image {
                transition: padding ease-in 0.2s;
                img {
                    transition: height ease-in 0.2s;
                }
            }
            .card-title {
                transition: margin-bottom ease-in 0.2s;
            }
            &:hover {
                .card-image {
                    padding-top: calc(80% - 15px);
                    img {
                        height: calc(100% + 15px);
                    }
                }
                .card-title {
                    margin-bottom: 40px; // 25 + 15 catch from animation to catch height
                }
            }
        }

        @include media-breakpoint-down(md) {
            flex-direction: row;
            .card-image {
                flex: 0 0 auto;
                width: 33.33333334%;
                max-width: 100%;
                padding-top: calc((((100% / 100) * 33.33333334) / 100) * 130);
            }
            .card-body {
                flex: 0 0 auto;
                width: 66.66666667%;
                max-width: 100%;
            }
            .card-title {
                margin-top: auto;
            }
        }
    }
}
