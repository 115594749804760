// FIGMA colouring scheme
$primary-1: #0e0e0e !default;               // Black            / Very dark gray (mostly black)
$primary-2: #fbfaf9 !default;               // White            / Light grayish orange
$primary-3: #f2efec !default;               // Sand             / Light grayish orange

$secondary-1-lighter: #dad7d4 !default;     // Brown Lightest   / Light grayish orange
$secondary-1-light: #b5afaa !default;       // Brown Light      / Grayish orange
$secondary-1: #837a71 !default;             // Brown            / Dark grayish orange
$secondary-1-dark: #68615a !default;        // Brown Dark       / Very dark grayish orange
$secondary-1-darker: #4f4944 !default;      // Brown Darkest    / Very dark grayish orange

$secondary-2-lighter: #bbc5c6 !default;     // Green Lightest   / Grayish cyan
$secondary-2-light: #768a8d !default;       // Green Light      / Dark grayish cyan
$secondary-2: #546d70 !default;             // Green            / Very dark grayish cyan
$secondary-2-dark: #3d5051 !default;        // Green Dark       / Very dark grayish cyan
$secondary-2-darker: #324143 !default;      // Green Darkest    / Very dark grayish cyan

$secondary-3-lighter: #aaafb7 !default;     // Grey Lightest    / Grayish blue
$secondary-3-light: #808793 !default;       // Grey Light       / Dark grayish blue
$secondary-3: #727987 !default;             // Grey             / Dark grayish blue
$secondary-3-dark: #5b616C !default;        // Grey Dark        / Very dark grayish blue
$secondary-3-darker: #444951 !default;      // Grey Darkest     / Very dark grayish blue

$accent-1-light: #f7ffc4 !default;          // Yellow Light     / Very pale yellow
$accent-1: #eaff6b !default;                // Yellow           / Very light yellow
$accent-1-dark: #c7d956 !default;           // Yellow Dark      / Soft yellow

$accent-2-light: #d6d7f8 !default;          // Lavender Light   / Light grayish blue
$accent-2: #bbbdf4 !default;                // Lavender         / Very soft blue
$accent-2-dark: #787ac1 !default;           // Lavender Dark    / Slightly desaturated blue

// BOOTSTRAP setup
$white: $primary-2;                         // White
$gray-200: $primary-3;                      // Sand
$black: $primary-1;                         // Black

$custom-grays: (
    "200": $gray-200
) !default;

// figma secondary-1
$orange-100: shift-color($secondary-1, -80%);
$orange-150: shift-color($secondary-1, -70%) !default;  // lightest
$orange-200: shift-color($secondary-1, -60%);
$orange-300: shift-color($secondary-1, -40%);           // light
$orange-400: shift-color($secondary-1, -20%);
$orange-500: $secondary-1;                              // default
$orange-600: shift-color($secondary-1, 20%);
$orange-602: shift-color($secondary-1, 20.5%) !default; // dark
$orange-700: shift-color($secondary-1, 40%);            // darkest
$orange-800: shift-color($secondary-1, 60%);
$orange-900: shift-color($secondary-1, 80%);
$custom-oranges: (
    "orange-100": $orange-100,
    "orange-200": $orange-150, // lightest
    "orange-300": $orange-300, // light
    "orange-400": $orange-400,
    "orange-500": $orange-500, // default
    "orange-600": $orange-602, // dark
    "orange-700": $orange-700, // darkest
    "orange-800": $orange-800,
    "orange-900": $orange-900
) !default;

// figma secondary-2
$cyan-100: shift-color($secondary-2, -80%);
$cyan-200: shift-color($secondary-2, -60%);         // lightest
$cyan-300: shift-color($secondary-2, -40%);
$cyan-400: shift-color($secondary-2, -20%);         // light
$cyan-500: $secondary-2;                            // default
$cyan-600: shift-color($secondary-2, 20%);
$cyan-635: shift-color($secondary-2, 27%) !default; // dark
$cyan-700: shift-color($secondary-2, 40%);          // darkest
$cyan-800: shift-color($secondary-2, 60%);
$cyan-900: shift-color($secondary-2, 80%);
$custom-cyans: (
    "cyan-100": $cyan-100,
    "cyan-200": $cyan-200, // lightest
    "cyan-300": $cyan-300,
    "cyan-400": $cyan-400, // light
    "cyan-500": $cyan-500, // default
    "cyan-600": $cyan-635, // dark
    "cyan-700": $cyan-700, // darkest
    "cyan-800": $cyan-700,
    "cyan-900": $cyan-900
) !default;

// figma secondary-3
$blue-100: shift-color($secondary-3, -80%);
$blue-200: shift-color($secondary-3, -60%);
$blue-300: shift-color($secondary-3, -40%);             // lightest
$blue-400: shift-color($secondary-3, -20%);
$blue-450: shift-color($secondary-3, -10%) !default;    // light
$blue-500: $secondary-3;                                // default
$blue-600: shift-color($secondary-3, 20%);              // dark
$blue-700: shift-color($secondary-3, 40%);              // darkest
$blue-800: shift-color($secondary-3, 60%);
$blue-900: shift-color($secondary-3, 80%);
$custom-blues: (
    "blue-100": $blue-100,
    "blue-200": $blue-200,
    "blue-300": $blue-300, // lightest
    "blue-400": $blue-450, // light
    "blue-500": $blue-500, // default
    "blue-600": $blue-600, // dark
    "blue-700": $blue-700, // darkest
    "blue-800": $blue-800,
    "blue-900": $blue-900
) !default;

// figma accent-1
$yellow-100: shift-color($accent-1, -80%);
$yellow-200: shift-color($accent-1, -60%);  // light
$yellow-300: shift-color($accent-1, -40%);
$yellow-400: shift-color($accent-1, -20%);
$yellow-500: $accent-1;                     // default
$yellow-575: shift-color($accent-1, 15%);   // dark
$yellow-600: shift-color($accent-1, 20%);
$yellow-700: shift-color($accent-1, 40%);
$yellow-800: shift-color($accent-1, 60%);
$yellow-900: shift-color($accent-1, 80%);
$custom-yellows: (
    "yellow-100": $yellow-100,
    "yellow-200": $yellow-200, // light
    "yellow-300": $yellow-300,
    "yellow-400": $yellow-400,
    "yellow-500": $yellow-500, // default
    "yellow-600": $yellow-575, // dark
    "yellow-700": $yellow-700,
    "yellow-800": $yellow-800,
    "yellow-900": $yellow-900
) !default;

// figma accent-2
$purple-100: shift-color($accent-2, -80%);
$purple-200: shift-color($accent-2, -60%);
$purple-300: shift-color($accent-2, -40%);  // light
$purple-400: shift-color($accent-2, -20%);
$purple-500: $accent-2;                     // default
$purple-600: shift-color($accent-2, 20%);
$purple-700: $accent-2-dark;                // dark
$purple-800: shift-color($accent-2, 60%);
$purple-900: shift-color($accent-2, 80%);
$custom-purples: (
    "purple-100": $purple-100,
    "purple-200": $purple-200,
    "purple-300": $purple-300, // light
    "purple-400": $purple-400,
    "purple-500": $purple-500, // default
    "purple-600": $purple-600,
    "purple-700": $purple-700, // dark
    "purple-800": $purple-800,
    "purple-900": $purple-900
) !default;

$blue: $blue-500;
$indigo: $purple-700;
$purple: $purple-500;
$pink: $purple-300;
$red: $orange-300;
$orange: $orange-500;
$yellow: $yellow-500;
$green: $cyan-200;
$teal: $cyan-400;
$cyan: $cyan-500;

$min-contrast-ratio: 4.5;
$color-contrast-dark: #000000;
$color-contrast-light: #ffffff;

// primary, secondary, tertiary, quaternary, quinary, senary, septenary, octonary, nonary, denary
$primary:       $purple;        // Lavender (Very soft blue)
$secondary:     $blue;          // Grey (Dark grayish blue)
$tertiary:      $cyan;          // Green (Very dark grayish cyan)
$quaternary:    $orange;        // Brown (Dark grayish orange)
$quinary:       $yellow;        // Yellow (Very light yellow)
$senary:        $red;           // Brown Light (Grayish orange)
$septenary:     $cyan-600;      // Green Dark (Very dark grayish cyan)
$octonary:      $orange-200;    // Brown Lightest (Light grayish orange)
$nonary:        $blue-600;      // Grey Dark (Very dark grayish blue)
$light:         $gray-200;      // Sand (Light grayish orange)
$dark:          $blue-700;      // Grey Darkest (Very dark grayish blue)

$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "light":      $light,
    "dark":       $dark
);

$custom-theme-colors: (
    "tertiary": $tertiary,
    "quaternary": $quaternary,
    "quinary": $quinary,
    "senary": $senary,
    "septenary": $septenary,
    "octonary": $octonary,
    "nonary": $nonary
) !default;

$primary-text-emphasis: shift-color($primary, 80%);
$secondary-text-emphasis: shift-color($secondary, -80%);

$primary-bg-subtle: tint-color($primary, 80%);
$secondary-bg-subtle: $orange-700;

$primary-border-subtle: $white;
$secondary-border-subtle: $purple-700;

$enable-negative-margins: true;
$enable-important-utilities: true;

$prefix: bs-;

$spacer: 1rem;
$custom-spacers: (
  6: $spacer * 4,
  7: $spacer * 5.5,
  8: $spacer * 7,
  9: $spacer * 8.5,
  10: $spacer * 10,
);

$custom-aspect-ratios: (
    "16x8": calc(8 / 16 * 100%)
) !default;

$body-color: $black;
$body-bg: $white;

// Assets Path
$assets-path: "/Assets/Kastori/Art" !default;

// Font Family
$font-family-path: "../../../Assets/fonts" !default;
$font-family-regular: "ApfelGrotezk-Regular" !default;
$font-family-medium: "ApfelGrotezk-Mittel" !default;
$font-family-name: "Apfel Grotezk" !default;
$font-family-sans-serif: "Apfel Grotezk", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 1rem;

// Icomoon
$icomoon-font-family: "komate" !default;
$icomoon-font-path: "#{$font-family-path}/icomoon/komate" !default;

$icon-map-marker: unquote('"\\e900"') !default;
$icon-globe: unquote('"\\e901"') !default;
$icon-envelope: unquote('"\\e902"') !default;
$icon-phone: unquote('"\\e903"') !default;
$icon-bars: unquote('"\\e904"') !default;
$icon-chevron-up: unquote('"\\e905"') !default;
$icon-chevron-right: unquote('"\\e906"') !default;
$icon-chevron-down: unquote('"\\e907"') !default;
$icon-chevron-left: unquote('"\\e908"') !default;
$icon-close: unquote('"\\e909"') !default;
$icon-facebook-square: unquote('"\\e90a"') !default;
$icon-instagram-square: unquote('"\\e90b"') !default;
$icon-linkedin-square: unquote('"\\e90c"') !default;
$icon-pinterest-square: unquote('"\\e90d"') !default;
$icon-twitter: unquote('"\\e90e"') !default;
$icon-x: unquote('"\\e90e"') !default;
$icon-youtube: unquote('"\\ea9d"') !default;
$icon-rocket: unquote('"\\e90f"') !default;
$icon-jet: unquote('"\\e90f"') !default;
$icon-spaceship: unquote('"\\e90f"') !default;
$icon-cog: unquote('"\\e910"') !default;

// Headings
$headings-font-family: $font-family-name;
$headings-font-style: normal;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2;
$headings-color: inherit;

$h1-font-size: 4.375rem;
$h2-font-size: 3rem;
$h3-font-size: 2.5rem;
$h4-font-size: 2rem;
$h5-font-size: $font-size-base * 1.5;
$h6-font-size: $font-size-base * 1.25;

// Buttons
$btn-border-radius: 0px 15px 15px 15px;
$btn-border-radius-sm: 0px 10px 10px 10px;
$btn-border-radius-lg: 0px 20px 20px 20px;

// Carousels
$carousel-control-color: $secondary-3-light;
$carousel-control-width: 60px;
$carousel-control-opacity: .85;
$carousel-control-hover-opacity: 1;

// Navbar
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg width='28' height='11' viewBox='0 0 28 11' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 0C0 1.65685 1.34315 3 3 3H26.5C27.3284 3 28 2.32843 28 1.5C28 0.671573 27.3284 0 26.5 0H0Z' fill='#{$black}'/><path d='M0 8C0 9.65685 1.34315 11 3 11H26.5C27.3284 11 28 10.3284 28 9.5C28 8.67157 27.3284 8 26.5 8H0Z' fill='#{$black}'/></svg>");
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg width='28' height='11' viewBox='0 0 28 11' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 0C0 1.65685 1.34315 3 3 3H26.5C27.3284 3 28 2.32843 28 1.5C28 0.671573 27.3284 0 26.5 0H0Z' fill='#{$white}'/><path d='M0 8C0 9.65685 1.34315 11 3 11H26.5C27.3284 11 28 10.3284 28 9.5C28 8.67157 27.3284 8 26.5 8H0Z' fill='#{$white}'/></svg>");

// Card
$card-title-color: $black;
