.accordion {
    --#{$prefix}border-color: transparent;
    --#{$prefix}accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='%230e0e0e'%3e%3cpath fill-rule='evenodd' d='M11.2501 17.2269L11.2501 5.85002C11.2501 5.6372 11.3219 5.459 11.4655 5.31542C11.6091 5.17182 11.7873 5.10002 12.0001 5.10002C12.2129 5.10002 12.3911 5.17182 12.5347 5.31542C12.6783 5.459 12.7501 5.6372 12.7501 5.85002L12.7501 17.2269L17.9193 12.0577C18.068 11.909 18.2421 11.8356 18.4414 11.8375C18.6408 11.8394 18.818 11.918 18.9732 12.0731C19.118 12.2282 19.193 12.4038 19.1982 12.6C19.2033 12.7961 19.1283 12.9718 18.9732 13.1269L12.6328 19.4673C12.5392 19.5609 12.4405 19.6269 12.3366 19.6653C12.2328 19.7038 12.1206 19.723 12.0001 19.723C11.8796 19.723 11.7674 19.7038 11.6636 19.6653C11.5597 19.6269 11.461 19.5609 11.3674 19.4673L5.02706 13.1269C4.88859 12.9884 4.81776 12.817 4.81456 12.6125C4.81136 12.408 4.88219 12.2282 5.02706 12.0731C5.18217 11.918 5.36037 11.8404 5.56166 11.8404C5.76294 11.8404 5.94114 11.918 6.09626 12.0731L11.2501 17.2269Z'/%3e%3c/svg%3e");
    --#{$prefix}accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='%23787ac1'%3e%3cpath fill-rule='evenodd' d='M11.2501 17.2269L11.2501 5.85002C11.2501 5.6372 11.3219 5.459 11.4655 5.31542C11.6091 5.17182 11.7873 5.10002 12.0001 5.10002C12.2129 5.10002 12.3911 5.17182 12.5347 5.31542C12.6783 5.459 12.7501 5.6372 12.7501 5.85002L12.7501 17.2269L17.9193 12.0577C18.068 11.909 18.2421 11.8356 18.4414 11.8375C18.6408 11.8394 18.818 11.918 18.9732 12.0731C19.118 12.2282 19.193 12.4038 19.1982 12.6C19.2033 12.7961 19.1283 12.9718 18.9732 13.1269L12.6328 19.4673C12.5392 19.5609 12.4405 19.6269 12.3366 19.6653C12.2328 19.7038 12.1206 19.723 12.0001 19.723C11.8796 19.723 11.7674 19.7038 11.6636 19.6653C11.5597 19.6269 11.461 19.5609 11.3674 19.4673L5.02706 13.1269C4.88859 12.9884 4.81776 12.817 4.81456 12.6125C4.81136 12.408 4.88219 12.2282 5.02706 12.0731C5.18217 11.918 5.36037 11.8404 5.56166 11.8404C5.76294 11.8404 5.94114 11.918 6.09626 12.0731L11.2501 17.2269Z'/%3e%3c/svg%3e");
    --#{$prefix}accordion-btn-color: #{$black};
    --#{$prefix}accordion-active-color: #{$purple-700};
    --#{$prefix}accordion-active-bg: transparent;
    --#{$prefix}accordion-btn-padding-x: 0;
    --#{$prefix}accordion-body-padding-x: 0;
    --#{$prefix}accordion-btn-focus-box-shadow: none;

    .accordion-button {
        font-size: 1.5rem;

        &:hover {
            color: #{$purple-700};
        }
    }

    .accordion-item {
        &:not(:last-child) {
            border-bottom-color: $blue-300;
        }
    }
}

.show-progress {
    background-color: $light;
    border: 1px solid $blue;
    border-radius: 1.1rem;
    padding: 1.5625rem 1.25rem 2.5rem 1.25rem;

    .accordion {
        --#{$prefix}accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230e0e0e'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        --#{$prefix}accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23787ac1'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        --#{$prefix}accordion-btn-color: #{$blue-600};

        .accordion-item {
            &:not(:last-child) {
                border-bottom-color: $blue;
            }
        }
    }

    .progress-bar {
        display: flex;
        position: relative;

        > div {
            background-color: $blue-600;
            border-radius: 0 50% 50% 50%;
            display: block;
            height: 1rem;
            position: relative;
            top: 0;
            transition: all ease-in 0.2s;
            width: 1rem;

            &.show {
                background-image: linear-gradient(90deg, rgba($yellow, 1) 49%, rgba($blue-600, 1) 50%);
                background-position: -0.5rem 50%;
                background-repeat: no-repeat;
                background-size: 7.5rem;
                border-radius: 0 0.5rem 0.5rem 1.25rem;
                width: 6rem;
                z-index: 1;

                &:after {
                    background-color: $yellow;
                    border-radius: 50%;
                    content: "";
                    inset: 0;
                    left: 50%;
                    position: absolute;
                    transform: translateX(-50%);
                    width: 1rem;
                    z-index: 1;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        border-radius: 2rem;
        padding: 4rem;
    }
}
