/**
 * @mixin button-variant:
 *    $background,
 *    $border,
 *    $color: color-contrast($background),
 *    $hover-background: if($color == $color-contrast-light, shade-color($background, $btn-hover-bg-shade-amount), tint-color($background, $btn-hover-bg-tint-amount)),
 *    $hover-border: if($color == $color-contrast-light, shade-color($border, $btn-hover-border-shade-amount), tint-color($border, $btn-hover-border-tint-amount)),
 *    $hover-color: color-contrast($hover-background),
 *    $active-background: if($color == $color-contrast-light, shade-color($background,$btn-active-bg-shade-amount), tint-color($background, $btn-active-bg-tint-amount)),
 *    $active-border: if($color == $color-contrast-light, shade-color($border, $btn-active-border-shade-amount), tint-color($border, $btn-active-border-tint-amount)),
 *    $active-color: color-contrast($active-background),
 *    $disabled-background: $background,
 *    $disabled-border: $border,
 *    $disabled-color: color-contrast($disabled-background)
 *
 * @mixin button-outline-variant:
 *    $color,
 *    $color-hover: color-contrast($color),
 *    $active-background: $color,
 *    $active-border: $color,
 *    $active-color: color-contrast($active-background)
 */

.btn {
    &.btn-primary {
        --#{$prefix}btn-hover-color: #{$white};
        --#{$prefix}btn-hover-bg: #{$purple-700};
        @include ripple($purple-700);
    }

    &.btn-secondary {
        --#{$prefix}btn-color: #{$white};
        --#{$prefix}btn-hover-color: #{$white};
        --#{$prefix}btn-hover-bg: #{$blue-700};
        @include ripple($blue-700);
    }

    &.btn-tertiary {
        @include ripple($cyan-700);
    }

    &.btn-quaternary {
        --#{$prefix}btn-color: #{$white};
        --#{$prefix}btn-hover-color: #{$white};
        --#{$prefix}btn-hover-bg: #{$orange-700};
        @include ripple($orange-700);
    }

    &.btn-light {
        @include ripple($purple-300);
    }

    &.btn-dark {
        --#{$prefix}btn-color: #{$purple-300};
        --#{$prefix}btn-hover-color: #{$white};
        --#{$prefix}btn-hover-bg: #{$purple-700};
        @include ripple($purple-700);
    }

    .fa {
        margin: 0 5px;
        transition: margin ease-in 0.2s;
    }

    &:not([data-module="scroll-to-top"]) {
        &:hover,
        &:active,
        &:focus {
            .fa {
                margin: 0 0 0 10px;
            }
        }
    }
}
