:root {
    --card-box-bg-color: #{$light};
    --card-box-bg-color-hover: #{$purple-300};
    --card-box-bg-color-focus: #{$purple-700};
    --card-box-title-color: #{$black};
    --carousel-bg-color: #{$light};
    --carousel-content-color: #{$black};
    --hero-bg-image-bg-color: #{$light};
    --hero-content-color: #{$black};
    --hero-content-headings-color: #{$black};
    --intro-cta-bg-color: #{$light};
    --intro-cta-title-color: #{$cyan-700};
    --quote-bg-color: #{$light};
    --quote-quotation-color: #{$purple};
    --quote-person-color: #{$light};
    --tower-accent-color: #{$yellow-600};
    --tower-bg-color: #{$light};
    --tower-border-color: #{$purple-700};
    --tower-content-color: #{$blue};
    --tower-title-color: #{$purple-700};
    --#{$prefix}heading-color: #{$black};
}
