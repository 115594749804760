@mixin headings($from: 1, $to: 6) {
    @for $i from $from through $to {
        h#{$i} {
            @content
        }
    }
}

@mixin ripple($radial) {
    background-position: center;
    background-size: 0%;
    transition: background-size 1s;

    &:focus,
    &:active {
        background-image: radial-gradient(circle, transparent 1%, $radial 1%);
        background-size: 15000%;
    }
}

@mixin btn-mask-fade-out-right($bg-color, $bg-hover-color) {
    position: relative;
    overflow: hidden;

    &:before {
        background-color: var(--#{$prefix}btn-hover-bg, #{$bg-hover-color});
        content: '';
        inset: 0;
        mask-image: url("/Assets/Komate/Art/mask/btn-mask.svg");
        mask-position: -40px center;
        mask-repeat: no-repeat;
        mask-size: 100px;
        position: absolute;
        transition: mask-size cubic-bezier(0.22, 1, 0.36, 1) 1000ms;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: var(--#{$prefix}btn-bg, #{$bg-color});

        &:before {
            mask-size: 200%;
        }
    }
}
