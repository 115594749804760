.tower-wrapper {
    .rich-text {
        max-width: 880px;
    }

    @include media-breakpoint-down(lg) {
        .rich-text {
            padding-left: calc(0.5 * $grid-gutter-width);
            padding-right: calc(0.5 * $grid-gutter-width);
        }
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 50px;

        .rich-text {
            padding-top: 50px;
        }
    }
}

.tower {
    padding: 5rem 0 0;

    .tower-item {
        background-color: var(--tower-bg-color, $white);
        border: 1px solid var(--tower-border-color, $purple-700);
        border-radius: 30px 30px 0 0;
        color: var(--tower-content-color, $black);
        margin-top: -1.5rem;
        overflow: hidden;
    }

    .tower-button {
        align-items: center;
        background-color: var(--tower-bg-color, $white);
        border: none;
        color: var(--tower-title-color, $purple-700);
        display: flex;
        font-size: 1.5rem;
        justify-content: space-between;
        padding: 1.5rem 1.5rem 3rem 1.5rem;
        text-align: left;
        width: 100%;

        .fa {
            color: var(--tower-accent-color, $yellow-600);
            transform: scaleY(1);
            transition: transform ease-in 0.2s;
        }

        &.collapsed {
            .fa {
                transform: scaleY(-1);
            }
        }
    }

    .tower-collapse {
        padding: 0 1.5rem 1.5rem;

        &.collapsing {
            transition: all ease-in-out 0.35s;

            .tower-body {
                visibility: hidden;
            }
        }
    }

    .tower-body {
        margin-top: -1.5rem;
        min-height: 100px;
    }

    @include media-breakpoint-down(lg) {
        margin-left: calc(-0.5 * $grid-gutter-width);
        margin-right: calc(-0.5 * $grid-gutter-width);
    }

    @include media-breakpoint-up(lg) {
        align-items: flex-start;
        display: flex;
        flex-wrap: nowrap;
        min-height: 575px;
        padding-top: 0;

        .tower-item {
            align-self: flex-end;
            border-radius: 80px 80px 0 0;
            display: flex;
            flex: 1 1 100%;
            flex-direction: column;
            justify-content: flex-end;
            margin-top: 0;
            min-height: 350px;

            &:first-child {
                border-radius: 0 80px 0 0;
            }

            &:last-child {
                border-radius: 80px 0 0 0;
            }
        }

        .tower-button {
            align-items: flex-start;
            flex-direction: column;
            gap: 24px;
            padding: 40px 50px;

            .fa {
                display: none;
                transform: scaleY(0);
                transition: none;
            }

            &.collapsed {
                align-items: flex-start;
                max-width: min-content;

                .fa {
                    display: block;
                    transform: scaleY(1);
                }
            }
        }

        .tower-collapse {
            padding: 0 50px 40px;
            position: relative;

            &:after {
                background-color: var(--tower-accent-color, $yellow-600);
                bottom: 0;
                content: '';
                height: 5px;
                left: 0;
                position: absolute;
                transition: width ease-in-out 0.2s;
                width: 0;
            }

            &.collapsing {
                .tower-body {
                    min-height: auto;
                    visibility: hidden;
                }
            }

            &.show {
                &:after {
                    width: 100%;
                }

                .tower-body {
                    display: block;
                }
            }
        }

        .tower-body {
            margin-top: 0;
            min-height: 330px;

            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
