.quote {
    line-height: 1.375;
    position: relative;
    z-index: 1;

    .quotation {
        color: var(--quote-quotation-color, $black);
    }

    .quote-background {
        aspect-ratio: 167/280;
        background-color: var(--quote-bg-color, $white);
        border-radius: 0 80px 80px 80px;
        height: 100%;
        inset: 0;
        left: 0;
        position: absolute;
        transform: translateX(50%);
        width: 50%;
        z-index: -1;
        animation-duration: 4s;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-fill-mode: none;
        animation-play-state: running;
        //animation-name: push;
        //animation-timeline: auto;
        //animation-range-start: normal;


        &:before,
        &:after {
            background-color: var(--quote-bg-color, $white);
            content: "";
            inset: 0;
            position: absolute;
            width: 20%;
        }

        &:before {
            //background-color: red;
            border-radius: 80px 80px 80px 0;
            left: 0;
            transform: translateX(-100%);
        }

        &:after {
            //animation: 4s linear 0s infinite alternate after-push;
            //background-color: orange;
            border-radius: 80px 80px 0 80px;
            left: 80%;
            transform: translateX(100%);
        }
    }

    .quote-wrapper {
        position: relative;
        overflow: hidden;
    }

    .fas {
        display: none;
    }

    .person {
        align-items: center;
        color: var(--quote-person-color, $primary-3);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 3rem 0 0;
        position: relative;
    }

    .person-title {
        //color: inherit;
        font-size: 1.5rem;
    }

    .person-text {
        font-size: 0.875rem;
    }

    @keyframes push {
        0% {
            width: 30%;
        }

        50% {
            width: 60%;
        }

        100% {
            width: 50%;
        }
    }

    @keyframes after-push {
        0% {
            left: 50%;
            width: 50%;
        }

        50% {
            left: 70%;
            width: 20%
        }

        100% {
            left: 60%;
            width: 30%;
        }
    }
}
